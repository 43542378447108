import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Grommet } from 'grommet';
import { zion } from '@zion-tech/ds-tokens';

const ZionTheme = ({
	children
}) => <ThemeProvider theme={zion}>
	    
	<Grommet theme={zion}>
		      
		{children}
		    
	</Grommet>
	  
</ThemeProvider>;

export default ZionTheme;