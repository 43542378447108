import React from "react";
import theme from "theme";
import { Theme, Link, Image, Text, Section, Strong, Em, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Zion Church | A.E Developers 
			</title>
			<meta name={"description"} content={"Somos uma Área Executiva da Zion Church com foco na transformação digital e inovação tecnológica.\n\nNossa missão é acelerar a expansão do reino de Deus na terra, apoiando a igreja de Cristo e as missões globais através de plataformas digitais.\n\nVocê pode contribuir conosco em Design, Produto, Coding ou Dados."} />
			<meta property={"og:title"} content={"Zion Church | A.E Developers "} />
			<meta property={"og:description"} content={"Somos uma Área Executiva da Zion Church com foco na transformação digital e inovação tecnológica.\n\nNossa missão é acelerar a expansão do reino de Deus na terra, apoiando a igreja de Cristo e as missões globais através de plataformas digitais.\n\nVocê pode contribuir conosco em Design, Produto, Coding ou Dados."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<meta name={"msapplication-TileColor"} content={"#000000"} />
		</Helmet>
		<Components.ZionThemeProvider>
			<Section
				display="flex"
				grid-gap="16px 24px"
				height="80px"
				position="absolute"
				background="rgba(0, 0, 0, 0) linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 54.3%) 0% 0% /auto repeat scroll padding-box"
				sm-top={0}
			>
				<Override
					slot="SectionContent"
					lg-font="32px --fontFamily-googleMontserrat"
					lg-color="--lightD1"
					display="flex"
					flex-direction="row"
					align-items="center"
					justify-content="space-between"
				/>
				<Image src="https://online.zionchurch.org.br/logo.png" display="block" object-fit="fill" width="48px" />
				<Text margin="0px 0px 0px 0px" padding="0px 0px 0px 16px" width="auto">
					Developers
				</Text>
				<Section display="flex">
					<Override
						slot="SectionContent"
						flex-direction="row-reverse"
						sm-font="9px Montserrat, sans-serif"
						sm-width="100px"
						sm-overflow-x="hidden"
						sm-overflow-y="hidden"
					/>
				</Section>
			</Section>
		</Components.ZionThemeProvider>
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="140px 0 80px 0"
			sm-padding="40px 0"
			background="linear-gradient(0deg,rgba(0,0,0,1) 3.8%,rgba(0, 0, 0, 0.6) 100%),rgb(0, 119, 204) url(https://images.unsplash.com/photo-1594904351111-a072f80b1a71?ixlib=rb-4.0.3&q=80&fm=jpg&crop=entropy&cs=tinysrgb&h=2000) 0% 0% /cover repeat scroll padding-box"
			height="100%"
			display="flex"
			align-items="center"
			justify-content="space-between"
			align-content="center"
		>
			<Override
				slot="SectionContent"
				align-content="center"
				justify-content="center"
				align-items="center"
				sm-margin="64px 24.0017px 0px 24.0017px"
			/>
			<Text
				as="h5"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				ÁREA EXECUTIVA DEVELOPERS
			</Text>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
				VENHA SERVIR CONOSCO
			</Text>
			<Text
				as="p"
				font="normal 500 24px/2 --fontFamily-googleMontserrat"
				margin="40px 0 20px 0"
				md-font="normal 500 18px/2 --fontFamily-googleMontserrat"
				sm-font="normal 500 16px/2 --fontFamily-googleMontserrat"
			>
				Somos uma Área Executiva da Zion Church com foco na transformação digital e inovação tecnológica. Nossa missão é{" "}
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						acelerar a expansão do reino de Deus na terra
					</Strong>
					,
				</Em>
				{" "}apoiando a igreja de Cristo e as missões globais através de plataformas digitais.
			</Text>
			<Button
				background="#00aa73"
				margin="40px 0px 0px 0px"
				display="block"
				width="fit-content"
				padding="28PX 44px 28px 44px"
				border-radius="20px"
				text-transform="uppercase"
				font="normal 600 15px/1.5 --fontFamily-googleMontserrat"
				type="link"
				href="https://forms.gle/3FccKLdUn8dZdbcVA"
				target="_blank"
				user-select="auto"
			>
				QUERO FAZER PARTE
			</Button>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"62fbc143f913c500201cb0fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<link href={"https://fonts.googleapis.com/css?family=Montserrat:400,600,700|Material+Icons"} rel={"stylesheet"} place={"endOfHead"} rawKey={"62fc37ddb08463aeb7aebd36"} />
		</RawHtml>
	</Theme>;
});