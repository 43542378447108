import React from "react";
import theme from "theme";
import { Theme, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Zion Church | A.E Developers 
			</title>
			<meta name={"description"} content={"Somos uma Área Executiva da Zion Church com foco na transformação digital e inovação tecnológica.\n\nNossa missão é acelerar a expansão do reino de Deus na terra, apoiando a igreja de Cristo e as missões globais através de plataformas digitais.\n\nVocê pode contribuir conosco em Design, Produto, Coding ou Dados."} />
			<meta property={"og:title"} content={"Zion Church | A.E Developers "} />
			<meta property={"og:description"} content={"Somos uma Área Executiva da Zion Church com foco na transformação digital e inovação tecnológica.\n\nNossa missão é acelerar a expansão do reino de Deus na terra, apoiando a igreja de Cristo e as missões globais através de plataformas digitais.\n\nVocê pode contribuir conosco em Design, Produto, Coding ou Dados."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<meta name={"msapplication-TileColor"} content={"#000000"} />
		</Helmet>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"62fbc143f913c500201cb0fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<link href={"https://fonts.googleapis.com/css?family=Montserrat:400,600,700|Material+Icons"} rel={"stylesheet"} place={"endOfHead"} rawKey={"62fc37ddb08463aeb7aebd36"} />
		</RawHtml>
	</Theme>;
});