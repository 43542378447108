export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"62fbc143f913c500201cb102",
				"633ce5b3fedca4001e364267",
				"62fbc143f913c500201cb105"
			]
		},
		"62fbc143f913c500201cb102": {
			"id": "62fbc143f913c500201cb102",
			"name": "404",
			"pageUrl": "404"
		},
		"62fbc143f913c500201cb105": {
			"id": "62fbc143f913c500201cb105",
			"name": "Home",
			"pageUrl": "index",
			"children": []
		},
		"633ce5b3fedca4001e364267": {
			"pageUrl": "developers",
			"id": "633ce5b3fedca4001e364267",
			"name": "Developers"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {
			"background": "#000"
		},
		"seo": {
			"favicon_32px": "https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z",
			"favicon_apple_152px": "https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z",
			"background_win10": "#000000",
			"title": "Zion Church | A.E Developers ",
			"description": "Somos uma Área Executiva da Zion Church com foco na transformação digital e inovação tecnológica.\n\nNossa missão é acelerar a expansão do reino de Deus na terra, apoiando a igreja de Cristo e as missões globais através de plataformas digitais.\n\nVocê pode contribuir conosco em Design, Produto, Coding ou Dados.",
			"og:description": "Somos uma Área Executiva da Zion Church com foco na transformação digital e inovação tecnológica.\n\nNossa missão é acelerar a expansão do reino de Deus na terra, apoiando a igreja de Cristo e as missões globais através de plataformas digitais.\n\nVocê pode contribuir conosco em Design, Produto, Coding ou Dados.",
			"og:title": "Zion Church | A.E Developers ",
			"og:image": "https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z",
			"hide_for_se": false,
			"robotsFileType": "autogenerated"
		}
	}
}