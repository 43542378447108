import React from "react";
import theme from "theme";
import { Theme, Link, Image, Text, Section, Box, Span, Strong, Em, Icon, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
import { FiFigma, FiCopy, FiCoffee } from "react-icons/fi";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"developers"} />
		<Helmet>
			<title>
				Zion Church | A.E Developers 
			</title>
			<meta name={"description"} content={"Somos uma Área Executiva da Zion Church com foco na transformação digital e inovação tecnológica.\n\nNossa missão é acelerar a expansão do reino de Deus na terra, apoiando a igreja de Cristo e as missões globais através de plataformas digitais.\n\nVocê pode contribuir conosco em Design, Produto, Coding ou Dados."} />
			<meta property={"og:title"} content={"Zion Church | A.E Developers "} />
			<meta property={"og:description"} content={"Somos uma Área Executiva da Zion Church com foco na transformação digital e inovação tecnológica.\n\nNossa missão é acelerar a expansão do reino de Deus na terra, apoiando a igreja de Cristo e as missões globais através de plataformas digitais.\n\nVocê pode contribuir conosco em Design, Produto, Coding ou Dados."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/logo.png?v=2022-11-04T18:15:39.912Z"} />
			<meta name={"msapplication-TileColor"} content={"#000000"} />
		</Helmet>
		<Components.ZionThemeProvider>
			<Section display="flex" grid-gap="16px 24px" height="80px" background="#1f2836">
				<Override
					slot="SectionContent"
					lg-font="32px --fontFamily-googleMontserrat"
					lg-color="--lightD1"
					display="flex"
					flex-direction="row"
					align-items="center"
					justify-content="space-between"
				/>
				<Image src="https://online.zionchurch.org.br/logo.png" display="block" object-fit="fill" width="48px" />
				<Text margin="0px 0px 0px 0px" padding="0px 0px 0px 16px" width="auto">
					Developers
				</Text>
				<Section display="flex">
					<Override slot="SectionContent" flex-direction="row-reverse" />
					<Components.ZionButton label="Quero Fazer Parte" />
				</Section>
			</Section>
			<Section
				background=",--color-darkL2 linear-gradient(0deg,--color-darkL1 24.3%,#1f2836 97.6%) center/cover"
				padding="64px 0 20px 0"
				sm-padding="40px 0"
				color="--light"
				font="--base"
				height="20px"
			/>
			<Section padding="0px 0 80px 0" background="--color-darkL1" sm-padding="60px 0 60px 0">
				<Section padding="0px 0 0px 0" sm-padding="60px 0 60px 0">
					<Override
						slot="SectionContent"
						flex-direction="row"
						flex-wrap="wrap"
						margin="0px 0 0px 0"
						width="100% border-box"
					/>
					<Box
						width="45%"
						lg-width="100%"
						lg-display="flex"
						lg-justify-content="center"
						padding="0px 0px 0px 0px"
						lg-padding="0px 0px 0px 0px"
					>
						<Box
							display="grid"
							grid-template-rows="repeat(9, 60px)"
							grid-template-columns="repeat(2, 1fr)"
							grid-gap="16px"
							max-width="480px"
							lg-margin="0px 0px 16px 0px"
							sm-grid-template-rows="repeat(9, 10vw)"
						>
							<Image
								src="https://images.unsplash.com/photo-1552664688-cf412ec27db2?ixlib=rb-1.2.1&q=80&cs=tinysrgb&fm=jpg&crop=entropy&h=2000"
								border-radius="24px"
								object-fit="cover"
								width="100%"
								grid-row="1 / span 5"
								grid-column="1 / span 1"
								height="100%"
							/>
							<Image
								src="https://images.unsplash.com/photo-1526925539332-aa3b66e35444?ixlib=rb-1.2.1&q=80&cs=tinysrgb&fm=jpg&crop=entropy&h=2000"
								border-radius="24px"
								object-fit="cover"
								grid-column="2 / span 1"
								grid-row="2 / span 3"
								width="100%"
								height="100%"
							/>
							<Image
								src="https://images.unsplash.com/photo-1618788372246-79faff0c3742?ixlib=rb-1.2.1&q=80&cs=tinysrgb&fm=jpg&crop=entropy&h=2000"
								border-radius="24px"
								object-fit="cover"
								width="100%"
								grid-column="1 / span 1"
								grid-row="6 / span 3"
								height="100%"
							/>
							<Image
								src="https://images.unsplash.com/photo-1520642413789-2bd6770d59e3?ixlib=rb-1.2.1&q=80&cs=tinysrgb&fm=jpg&crop=entropy&h=2000"
								border-radius="24px"
								object-fit="cover"
								width="100%"
								grid-column="2 / span 1"
								grid-row="5 / span 5"
								height="100%"
							/>
						</Box>
					</Box>
					<Box
						display="flex"
						width="50%"
						flex-direction="column"
						justify-content="center"
						align-items="flex-start"
						lg-align-items="center"
						sm-margin="20px 0px 0px 0px"
						sm-padding="0px 0px 0px 0px"
						padding="16px 0px 16px 0px"
						lg-width="100%"
						lg-padding="24px 0px 0px 0px"
					>
						<Text
							margin="0px 0px 24px 0px"
							color="--lightD2"
							font="normal 800 62px/1.2 --fontFamily-googleMontserrat"
							lg-text-align="center"
							sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						>
							Estamos construindo o futuro da Igreja no mundo digital.
						</Text>
						<Text margin="42px 0px 0px 0px" color="--greyD3" font="normal 300 20px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" lg-text-align="center">
							Somos uma{" "}
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Área Executiva da Zion Church
							</Span>
							{" "}com foco na transformação digital e inovação tecnológica.{" "}
							<br />
							<br />
							Nossa missão é{" "}
							<Em
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									acelerar a expansão do reino de Deus na terra
								</Strong>
								,
							</Em>
							{" "}apoiando a igreja de Cristo e as missões globais através de plataformas digitais.
							<br />
							<br />
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Você pode contribuir conosco em Design, Produto, Coding ou Dados.
							</Span>
						</Text>
					</Box>
				</Section>
			</Section>
			<Section padding="40px 0 80px 0" sm-padding="60px 0 60px 0">
				<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="24px 4%" md-grid-template-columns="1fr">
					<Box justify-content="flex-start" flex-direction="column" display="flex">
						<Icon
							category="fi"
							icon={FiFigma}
							margin="0px 0px 16px 0px"
							color="--light"
							size="48px"
							padding="6px 6px 6px 6px"
						/>
						<Text margin="0px 0px 18px 0px" color="--lightD1" font="--headline3" lg-text-align="left">
							Design
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--grey"
							font="--base"
							lg-text-align="left"
							flex="1 0 auto"
						>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
						</Text>
					</Box>
					<Box justify-content="flex-start" flex-direction="column" display="flex">
						<Icon
							category="fi"
							icon={FiCopy}
							margin="0px 0px 16px 0px"
							color="--light"
							size="48px"
							padding="6px 6px 6px 6px"
						/>
						<Text margin="0px 0px 18px 0px" color="--lightD1" font="--headline3" lg-text-align="left">
							Produto
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--grey"
							font="--base"
							lg-text-align="left"
							flex="1 0 auto"
						>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
						</Text>
					</Box>
					<Box justify-content="flex-start" flex-direction="column" display="flex">
						<Icon
							category="fi"
							icon={FiCoffee}
							margin="0px 0px 16px 0px"
							color="--light"
							size="48px"
							padding="6px 6px 6px 6px"
						/>
						<Text margin="0px 0px 18px 0px" color="--lightD1" font="--headline3" lg-text-align="left">
							Coding & Dados
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--grey"
							font="--base"
							lg-text-align="left"
							flex="1 0 auto"
						>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
						</Text>
					</Box>
				</Box>
			</Section>
			<Section
				background="linear-gradient(0deg,#04080c 7.6%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url() center/cover"
				padding="92px 0 64px 0"
				sm-padding="40px 0"
				color="--light"
				font="--base"
			>
				<Stack>
					<StackItem width="75%" lg-width="100%">
						<Override slot="StackItemContent" flex-direction="column" sm-font="300 16px/24px --fontFamily-googleMontserrat" />
						<Text
							color="#00cc9f"
							letter-spacing="1px"
							text-transform="uppercase"
							margin="0"
							font="700 16px/24px -apple-system, system-ui, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif"
						>
							&lt; MUITO ALÉM DE CÓDIGO /&gt;
						</Text>
						<Text
							as="h1"
							font="normal 900 64px/1.2 --fontFamily-googleMontserrat"
							md-font="--headline2"
							margin="10px 0"
							sm-font="--headline2"
							width="900px"
						>
							Estamos formando discípulos de tecnologia
							<br />
							para manifestar o Reino de Deus na sociedade.
						</Text>
					</StackItem>
				</Stack>
				<Box text-align="center" margin="96px 0 0 0" font="normal 500 28px/1.2 &quot;Montserrat&quot;, sans-serif">
					<Text margin="8px 0" text-transform="uppercase" font="500 17px/33.6px Montserrat, sans-serif">
						conheça nossaS INICIATIVAS
					</Text>
					<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
				</Box>
			</Section>
			<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-dark">
				<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
				<Box
					display="grid"
					grid-template-columns="repeat(3, 1fr)"
					grid-gap="32px"
					lg-grid-template-columns="repeat(2, 1fr)"
					sm-grid-template-columns="1fr"
					width="100%"
				>
					<LinkBox
						display="flex"
						md-flex-direction="column"
						md-align-items="center"
						md-justify-content="center"
						flex-wrap="wrap"
						sm-width="100%"
						grid-column="1 / span 1"
						sm-align-self="auto"
						sm-grid-column="auto"
						justify-content="flex-start"
					>
						<Box
							width="100%"
							align-items="flex-start"
							display="flex"
							justify-content="flex-start"
							md-width="100%"
							md-margin="0px 0px 0px 0px"
							lg-width="100%"
						>
							<Image
								src="https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/podcast.jpeg?v=2022-10-05T03:11:17.291Z"
								border-radius="24px"
								max-width="100%"
								width="100%"
								object-fit="cover"
								lg-max-height="392px"
								object-position="50% 50%"
								sm-max-height="213px"
								height="522px"
							/>
						</Box>
						<Box
							width="100%"
							padding="32px 12px 16px 12px"
							display="flex"
							align-items="flex-start"
							justify-content="flex-start"
							md-width="100%"
							md-border-width="0px"
							flex-direction="column"
						>
							<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
								Podcasts
							</Text>
							<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".5">
								Mensalmente discutimos como a tecnologia pode ser usada para a expansão da igreja no mundo digital.
							</Text>
						</Box>
					</LinkBox>
					<LinkBox
						display="flex"
						md-flex-direction="column"
						md-align-items="center"
						md-justify-content="center"
						flex-wrap="wrap"
						sm-width="100%"
						grid-column="2 / span 2"
						lg-grid-column="2 / span 1"
						sm-align-self="auto"
						sm-grid-column="auto"
						justify-content="flex-start"
					>
						<Box
							width="100%"
							align-items="flex-start"
							display="flex"
							justify-content="flex-start"
							md-width="100%"
							md-margin="0px 0px 0px 0px"
							lg-width="100%"
						>
							<Image
								src="https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/WhatsApp%20Image%202022-09-03%20at%2012.14.35.jpeg?v=2022-10-05T03:16:39.497Z"
								border-radius="24px"
								max-width="100%"
								width="100%"
								object-fit="cover"
								lg-max-height="392px"
								object-position="50% 50%"
								sm-max-height="213px"
								height="522px"
							/>
						</Box>
						<Box
							width="100%"
							padding="32px 12px 16px 12px"
							display="flex"
							align-items="flex-start"
							justify-content="flex-start"
							md-width="100%"
							md-border-width="0px"
							flex-direction="column"
						>
							<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
								Projetos de plataforma digital
							</Text>
							<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".5">
								Estamos constantemente construindo soluções inovadoras para apoiar a expansão do Reino de Deus através da Zion Church, nós trabalhamos em sprints quinzenais em squads de desenvolvimento que possuem o foco de entregar inovação e transformação digital.
							</Text>
						</Box>
					</LinkBox>
					<LinkBox
						display="flex"
						md-flex-direction="column"
						md-align-items="center"
						md-justify-content="center"
						flex-wrap="wrap"
						lg-align-items="flex-start"
						sm-width="100%"
						grid-column="1 / span 2"
						lg-grid-column="1 / span 1"
						sm-align-self="auto"
						sm-grid-column="auto"
						justify-content="flex-start"
						margin="0px 0px 0px 0px"
						padding="48px 0px 0px 0px"
					>
						<Box
							width="100%"
							align-items="flex-start"
							display="flex"
							justify-content="flex-start"
							md-width="100%"
							md-margin="0px 0px 0px 0px"
							lg-width="100%"
						>
							<Image
								src="https://uploads.quarkly.io/62fbc143f913c500201cb0fd/images/WhatsApp%20Image%202022-08-20%20at%2011.08.33.jpeg?v=2022-10-05T03:04:59.270Z"
								border-radius="24px"
								max-width="100%"
								width="100%"
								object-fit="cover"
								lg-max-height="392px"
								object-position="50% 50%"
								sm-max-height="213px"
								height="522px"
							/>
						</Box>
						<Box
							width="100%"
							padding="32px 12px 16px 12px"
							display="flex"
							align-items="flex-start"
							justify-content="flex-start"
							md-width="100%"
							md-border-width="0px"
							flex-direction="column"
						>
							<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
								Treinamentos & Capacitação
							</Text>
							<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".5">
								Acreditamos que devemos formar novos discípulos de tecnologia que irão usar seu tempo, talento e tesouro para mudar a sociedade, nosso objetivo é capacitar pessoas interessadas com a visão do Reino e o caráter de Cristo para ocupar lugares de influência no mercado de T.I
							</Text>
						</Box>
					</LinkBox>
					<LinkBox
						display="flex"
						md-flex-direction="column"
						md-align-items="center"
						md-justify-content="center"
						flex-wrap="wrap"
						sm-width="100%"
						grid-column="3 / span 1"
						lg-grid-column="2 / span 1"
						sm-align-self="auto"
						sm-grid-column="auto"
						justify-content="flex-start"
						margin="0px 0px 0px 0px"
						padding="48px 0px 0px 0px"
					>
						<Box
							width="100%"
							align-items="flex-start"
							display="flex"
							justify-content="flex-start"
							md-width="100%"
							md-margin="0px 0px 0px 0px"
							lg-width="100%"
						>
							<Image
								src="https://images.unsplash.com/photo-1576085898323-218337e3e43c?ixlib=rb-1.2.1&q=80&cs=tinysrgb&fm=jpg&crop=entropy&w=2000"
								border-radius="24px"
								max-width="100%"
								width="100%"
								object-fit="cover"
								lg-max-height="392px"
								object-position="50% 50%"
								sm-max-height="213px"
								height="522px"
							/>
						</Box>
						<Box
							width="100%"
							padding="32px 12px 16px 12px"
							display="flex"
							align-items="flex-start"
							justify-content="flex-start"
							md-width="100%"
							md-border-width="0px"
							flex-direction="column"
						>
							<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
								Zion Tech Talks
							</Text>
							<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".5">
								Mensalmente trazemos para a mesa temas para impulsionar o conhecimento sobre liderança, tecnologia e inovação.
							</Text>
						</Box>
					</LinkBox>
				</Box>
			</Section>
		</Components.ZionThemeProvider>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"62fbc143f913c500201cb0fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<link href={"https://fonts.googleapis.com/css?family=Montserrat:400,600,700|Material+Icons"} rel={"stylesheet"} place={"endOfHead"} rawKey={"62fc37ddb08463aeb7aebd36"} />
		</RawHtml>
	</Theme>;
});