import React from 'react';
import atomize from "@quarkly/atomize";
import styled from 'styled-components';
import { Button } from 'grommet';
const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  font-size: 14px;
  border-radius: ${props => props.rounded ? '999px' : '4px'};
  pointer-events: ${props => props.loading ? 'none' : 'initial'};
  box-shadow: ${props => props.variant !== 'ghost' && `0px 2px 5px #0000009e`};
  user-select: none;
  transition: all 0.3s;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: ${props => !props.disabled && props.variant !== 'ghost' && `0px 3px 7px #0000009e`};
    transform: ${props => !props.disabled && props.variant !== 'ghost' && `translate(0px, -2px)`};
  }

  &:active {
    box-shadow: ${props => !props.disabled && props.variant !== 'ghost' && `0px 2px 5px #0000009e`};
    transform: ${props => !props.disabled && props.variant !== 'ghost' && `translate(0px, 0px)`};
  }
`;

const ZionButton = ({
	label
}) => <StyledButton rounded label={label} secondary />;

export default atomize(ZionButton)({
	name: "ZionButton",
	description: {
		en: "ZionButton"
	},
	propInfo: {
		label: {
			control: "input"
		},
		variant: {
			control: "input"
		}
	}
});